<template>
  <div class="login">
    <div class="form-box" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
      <div class="title"><h1 title="Lin">后台管理系统</h1></div>
      <form class="login-form" autocomplete="off" @submit.prevent="throttleLogin()">
        <div class="form-item nickname">
          <el-input placeholder="请填写用户名" v-model="account.username" autocomplete="off">
            <template #prepend>
              <i class="el-icon-user"></i>
            </template>
          </el-input>
        </div>
        <div class="form-item password">
          <el-input placeholder="请填写登录密码" v-model="account.password" autocomplete="off" show-password>
            <template #prepend>
              <i class="el-icon-key"></i>
            </template>
          </el-input>
        </div>
        <div class="form-item captcha">
          <el-input v-model="account.captcha" autocomplete="off" placeholder="请填写验证码"></el-input>
          <div class="captcha" v-loading="!captchaImage">
            <img alt="验证码" :src="captchaImage" @click="debounceGetCaptcha" />
          </div>
        </div>
        <button class="submit-btn" type="submit">登录</button>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import UserModel from '@/lin/model/user'
import Utils from '@/lin/util/util'
import Config from '@/config'
import { encrypt } from '@/lin/util/aes-encrypt.js'
import { ElMessage } from 'element-plus'

export default {
  setup() {
    let tag = ''
    const wait = 2000 // 2000ms之内不能重复发起请求
    const loading = ref(false)
    const captchaImage = ref('')
    const store = useStore()
    const router = useRouter()
    const throttleLogin = ref(null)
    const debounceGetCaptcha = ref(null)
    const intervalId = ref('')

    const account = reactive({
      username: '',
      password: '',
      captcha: '',
      uuid: '',
    })

    /**
     * 根据账号密码登录，拿到 token 并储存
     */
    const login = async () => {
      const { username, password, captcha, uuid } = account

      if (!username || !password || !captcha) return ElMessage.warning('请将登录信息填写完整')
      let encryptPwd = encrypt(password)

      try {
        loading.value = true
        let res = await UserModel.getToken(username, encryptPwd, captcha, uuid)
        clearIntervalId()
        const { data } = res
        console.log(res, data)
        await getInformation()
        loading.value = false
        router.push(Config.defaultRoute)
        ElMessage.success('登录成功')
      } catch (err) {
        loading.value = false
        console.log(err)
        const { data } = err
        ElMessage.error(data.message)
        // 10033验证码过期 10021用户名不存在 10031用户名或密码错误 重新获取
        if (data.code == 10021 || data.code == 10031 || data.code == 10033) getCaptcha()
      }
    }

    const getCaptcha = async () => {
      let res = await UserModel.getCaptcha()
      account.uuid = res.uuid
      captchaImage.value = res.captcha
    }

    /**
     * 获取并更新当前管理员信息
     */
    const getInformation = async () => {
      try {
        // 尝试获取当前用户信息
        const user = await UserModel.getPermissions()
        store.dispatch('setUserAndState', user)
        store.commit('SET_USER_PERMISSIONS', user.permissions)
      } catch (e) {
        console.error(e)
      }
    }

    // 清除定时器
    const clearIntervalId = () => {
      clearInterval(intervalId.value)
    }

    /**
     * 节流登录
     */
    onMounted(() => {
      getCaptcha()
      throttleLogin.value = Utils.throttle(login, wait)
      debounceGetCaptcha.value = Utils.debounce(getCaptcha, 100)
    })

    return {
      account,
      loading,
      captchaImage,
      throttleLogin,
      getCaptcha,
      debounceGetCaptcha,
    }
  },
}
</script>

<style lang="scss">
.login {
  width: 100%;
  height: 100%;
  background-size: auto;
  background: #1b2c5f url('../../assets/image/login/login-ba.png') no-repeat center center;

  .team-name {
    position: fixed;
    left: 40px;
    top: 50%;
    width: 50px;
    transform: translateY(-50%);
  }

  .logo {
    width: 231px;
    height: 93px;
    position: fixed;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
  }

  .form-box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    height: 500px;
    background: rgba(32, 54, 122, 0.9);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      height: 37px;
      font-size: 30px;
      line-height: 37px;
      margin-bottom: 15%;

      h1 {
        box-sizing: border-box;
        text-align: center;
        color: #fff;
      }
    }

    .login-form {
      width: 380px;
      margin: 0 auto;

      .form-item {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        // padding: 13px;
        margin-bottom: 34px;
        position: relative;
        margin-bottom: 34px;
        // background: #e2e2e3;
        display: flex;
        align-items: center;
        &.captcha {
          .el-input__wrapper {
            border-radius: 7px;
          }
        }
        .el-input__wrapper {
          border: 1px solid $theme;
          border-top-right-radius: 7px;
          border-bottom-right-radius: 7px;
        }
        .el-input-group__prepend {
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
        }
        .el-input__inner {
          box-shadow: none !important;
          border: 0;
          height: 40px;
          // background: #fff;
          border-radius: 0 7px 7px 0;
        }
        .el-icon-user,
        .el-icon-key {
          font-size: 20px;
        }
        input {
          width: 100%;
          background: transparent;
          color: #818181;
          font-size: 16px;
          padding-left: 14px;
          box-sizing: border-box;
        }
        .input-ic {
          width: 25px;
          height: 25px;
        }
        .captcha {
          margin-left: 20px;
          width: 130px;
          min-height: 40px;
          flex-shrink: 0;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .form-item.nickname {
        // background: url('../../assets/image/login/nickname.png') no-repeat;
        background-size: 100% auto;
        background-position: left bottom;
      }

      .form-item.password {
        // background: url('../../assets/image/login/password.png') no-repeat;
        background-size: 100% auto;
        background-position: left bottom;
      }

      .submit-btn {
        width: 100%;
        height: 50px;
        color: #fff;
        font-size: 16px;
        text-align: center;
        box-sizing: border-box;
        padding: 0 10px;
        background: $theme;
        // background: url('../../assets/image/login/login-btn.png') no-repeat;
        background-size: 90% auto;
        background-position: center bottom;
        border: none;
        border-radius: 7px;
        cursor: pointer;
      }
    }
  }
}
</style>
